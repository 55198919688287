import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/sass-variables",
  "date": "2015-01-28",
  "title": "SASS – VARIABLES",
  "author": "admin",
  "tags": ["development", "css", "sass"],
  "featuredImage": "feature.jpg",
  "excerpt": "Native CSS variable support is still in its infancy, but Sass affords us a way to set reusable values."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In Sass, Variable names begin with $, like $base.`}</p>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$base: #777777;

.sidebar {
    border: 1px solid $base;
    p {
        color: $base;
    }
}   
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sidebar {
    border: 1px solid #777777;
}
.sidebar p {
    color: #777777;
}
`}</code></pre>
    <h2>{`The Default Flag`}</h2>
    <p>{`Variable definitions can optionally take the !default flag:`}</p>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$title: 'My Blog';
$title: 'About Me'; // overrides the first value

h2:before {
    content: $title;
}
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h2:before {
    content: 'About Me';
}
`}</code></pre>
    <p>{`Let's resolve the above issue with the !default flag:`}</p>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$title: 'My Blog';
$title: 'About Me' !default;

h2:before {
    content: $title;
}
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h2:before {
    content: 'My Blog';
}
`}</code></pre>
    <p>{`Here at line 2, since a value exists, it isn't overwritten.`}</p>
    <p><strong parentName="p">{`_buttons.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$rounded: 3px !default;

.btn-a {
    border-radius: $rounded;
    color: #777;
}
.btn-b {
    border-radius: $rounded;
    color: #222;
}
`}</code></pre>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$rounded: 5px;
@import "buttons";
`}</code></pre>
    <p>{`Here, if a value hasn't defined elsewhere, it is used by default.`}</p>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn-a {
border-radius: 5px;
    color: #777;
}

.btn-b {
border-radius: 5px;
    color: #222;
}
`}</code></pre>
    <h2>{`Variable Declaration + Use`}</h2>
    <h2>{`Booleans`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$rounded: false;
$shadow: true;
`}</code></pre>
    <h2>{`Numbers`}</h2>
    <p>{`Can be set with or without units:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$rounded: 4px;
$line-height: 1.5;
$font-size: 3rem;
`}</code></pre>
    <h2>{`Types`}</h2>
    <h2>{`Colors`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$base: purple;
$border: rgba(0, 255, 0, 0.5);
$shadow: #333;
`}</code></pre>
    <h2>{`Strings`}</h2>
    <p>{`Can be set with or without quotes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$header: 'Helvetica Neue';
$callout: Arial;
$message: "Loading...";
`}</code></pre>
    <h2>{`Lists`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$authors: nick, dan, aimee, drew;
$margin: 40px 0 20px 100px;
`}</code></pre>
    <h2>{`Null`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$shadow: null;
`}</code></pre>
    <h2>{`Scope`}</h2>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`p {
    $border: #ccc;
    border-top: 1px solid $border;
}
h1 {
    border-top: 1px solid $border;
}
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`Syntax error: Undefined
variable: "$border"
`}</code></pre>
    <p>{`Here border isn't available outside of its own block.`}</p>
    <h2>{`Reassignment in a Declaration`}</h2>
    <p>{`Variables set inside a declaration (within { }) aren't usable outside that block.
Setting new values to variables set outside a declaration changes future instances.`}</p>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$color-base: #777777;
.sidebar {
    $color-base: #222222;
    background: $color-base;
}
p {
    color: $color-base;
}
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sidebar {
    background: #222222;
}
p {
    color: #222222;
}
`}</code></pre>
    <p>{`As the highlighted line above, overwriting a variable in a declaration is global.`}</p>
    <p>{`Use the Ruby-esque #{$variable} to shim variables into selectors, property names, and strings:`}</p>
    <p><strong parentName="p">{`style.scss`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$side: top;

sup {
    position: relative;
    #{$side}: -0.5em;
}
.callout-#{$side} {
    background: #777;
}
`}</code></pre>
    <p><strong parentName="p">{`style.css`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`sup {
    position: relative;
    top: -0.5em;
}
.callout-top {
    background: #777;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      